<template>
    <div class="main-content flex-shrink-0">
        <div class="container" :class="{'container-wide': $route.meta.wideContainer}">
            <div class="row">
                <div class="col-12 col-xl-4 d-none d-xl-block"  v-if="!$route.meta.hideSideBar">
                    <div class="account-sidebar">

                        <div class="account-sidebar-block">
                            <p class="h2">Профиль</p>
                            <ul class="account-sidebar-nav">
                                <li class="active">
                                    <router-link :to="{name:'personal.area'}">Персональные данные</router-link>
<!--                                    <div class="label">1/8</div>-->
                                </li>
                                <li>
                                    <router-link :to="{name:'payment.area'}">Настройка оплаты</router-link>
<!--                                    <div class="label">-->
<!--                                        <div class="ok"></div>-->
<!--                                    </div>-->
                                </li>
                                <li>
                                    <router-link :to="{name:'profile.area'}">Данные профиля</router-link>
<!--                                    <div class="label">1/12</div>-->
                                </li>
                                <li>
                                    <router-link :to="{name:'secure.area'}">Безопасность</router-link>
<!--                                    <div class="label">-->
<!--                                        <div class="error"></div>-->
<!--                                    </div>-->
                                </li>
<!--                                <li>-->
<!--                                    <router-link :to="{name:'notification.area'}">Оповещения</router-link>-->
<!--                                </li>-->
                                <li>
                                    <router-link :to="{name:'favorite.area'}">Избранное</router-link>
                                </li>
                            </ul>
                        </div>

                        <div class="account-sidebar-block">
                            <p class="h2">Заказы</p>
                            <ul class="account-sidebar-nav">
                                <li class="active">
                                    <router-link :to="{name:'order.area'}">Заказы</router-link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
				<div class="col-12" :class="{'col-xl-8':!$route.meta.hideSideBar}">
                	<router-view></router-view>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'UserArea',
};
</script>

<style scoped>

</style>