<script>
import TimeCalendar from "@/components/widgets/TimeCalendar.vue";
import datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker/src";
import * as lang from "vuejs-datepicker/dist/locale";
import Api from "@/components/lk/service";
import moment from "moment/moment";

let api = undefined;
export default {
  name: "OrderPhotographer",
  components: {TimeCalendar, datepicker, VueTimepicker},
  data() {
    return {
      towns: [],
      calendarDate: '',
      languages: lang,
      selectedCity: undefined,
      nonWorkingHours: [],
      nonWorkingHoursToDelete: [],
      calendarOrders: [],
      calendarNonWorkingHours: [],
      highlighted: {
        dates: [],
      },
      nonWorkingHour: {
        start_time: {HH: '08', mm: '00'},
        end_time: {HH: '09', mm: '00'}
      },
      calendar: [],
    }
  },
  mounted() {
    api = new Api();
    this.calendarDate = moment().toDate();
    if (this.isPhotographer()) {
      this.loadCities();
    }
  },
  watch:{
    selectedCity: {
      handler: function () {
        this.calendar = [];
        if (this.isPhotographer()) {
          this.loadDates();
          // this.loadOrdersByTown();
          this.loadNonWorkingHours();
        } else {
          // this.loadOrders();
        }
      },
      deep: true
    },
    calendarDate: {
      handler: function (newValue, oldVal) {
        if (newValue !== '' && moment(newValue).format('YYYY-MM-DD') === moment(oldVal).format('YYYY-MM-DD')) {
          let selectedDates = this.highlighted.dates.filter(date => moment(date).format('YYYY-MM-DD') === moment(newValue).format('YYYY-MM-DD'));

          if (selectedDates.length > 0) {
            let index = this.highlighted.dates.findIndex(date => moment(date).format('YYYY-MM-DD') === moment(newValue).format('YYYY-MM-DD'));
            this.highlighted.dates.splice(index, 1);
            this.travelDates.splice(index, 1);
          } else {
            this.highlighted.dates.push(newValue);
            const date = moment(newValue).format('YYYY-MM-DD');
            this.travelDates.push({date: date, is_day_off: this.isMain()});
          }
        }

        let selected = moment(newValue).format('YYYY-MM-DD');
        // this.calendarOrders = [];
        // this.orders.forEach((order) => {
        //   let start = moment(order.start).format('YYYY-MM-DD');
        //   if (start === selected) {
        //     this.calendarOrders.push(order);
        //   }
        // });
        this.fetchNonWorkHours(selected);
        // this.calendarNonWorkingHours = [];
        // this.nonWorkingHours.forEach((hour)=>{
        //     let start = moment(hour.date).format('YYYY-MM-DD');
        //     if(start === selected){
        //       let date = moment(hour.date);
        //       let start = moment(date).toDate();
        //       let end = moment(date).toDate();
        //       start.setTime(hour.start);
        //       end.setTime(hour.end);
        //       // start.setHours(hour.start.HH);
        //       // start.setMinutes(hour.start.mm);
        //       // end.setHours(hour.end.HH);
        //       // end.setMinutes(hour.end.mm);
        //       this.calendarNonWorkingHours.push(
        //           {
        //             start: start,
        //             end: end
        //           }
        //       );
        //       this.calendarNonWorkingHours.push(hour);
        //     }
        //   });

        this.$forceUpdate();
      },
      deep: true
    }
  },
  methods: {
    loadCities() {
      api
          .getCities()
          .then((res) => {
            if (res.data.data.length > 0) {
              this.selectedCity = this.mainCity = res.data.data.find(arr =>
                  arr.is_main === true
              ).city;
              this.towns = res.data.data;
            }
          });

    },
    addNonWorkingHours() {
      this.$bvModal.show('modal-NonWorkingHours');
    },
    setNonWorkingHour() {
      this.$bvModal.hide('modal-NonWorkingHours');
      let selectedDay = this.clone(this.calendarDate);
      let start = moment(selectedDay).toDate();
      let end = moment(selectedDay).toDate();
      start.setHours(this.nonWorkingHour.start_time.HH);
      start.setMinutes(this.nonWorkingHour.start_time.mm);
      end.setHours(this.nonWorkingHour.end_time.HH);
      end.setMinutes(this.nonWorkingHour.end_time.mm);
      const tempId = Math.random();
      this.calendarNonWorkingHours.push(
          {
            start: start,
            end: end,
            tempId: tempId
          }
      );

      this.nonWorkingHours.push(
          {
            date: moment(selectedDay).format('YYYY-MM-DD'),
            tempId: tempId,
            start: this.nonWorkingHour.start_time.HH + ':' + this.nonWorkingHour.start_time.mm,
            end: this.nonWorkingHour.end_time.HH + ':' + this.nonWorkingHour.end_time.mm
          }
      )
    },
    loadDates() {
      api
          .getTravels(this.selectedCity.id)
          .then((res) => {
            this.calendar = res.data.data.calendar;
            this.travelDates = [];
            this.highlighted.dates = [];
            res.data.data.travels.forEach((date) => {
              this.highlighted.dates.push(new Date(date.date));
              this.travelDates.push({date: moment(date.date).format('YYYY-MM-DD'), is_day_off: date.is_day_off});
            });
          });
    },
    isMain() {
      if (typeof this.selectedCity == "undefined" || typeof this.mainCity == "undefined") {
        return false;
      }
      return this.selectedCity.id === this.mainCity.id;
    },
    saveJournal() {
      if (this.travelDates.length > 0) {
        api
            .updateTravels(this.selectedCity.id, this.travelDates)
            .then((res) => {
              this.$root.$emit('successful', 'Дневник обновлен');
            });
      }

      if (this.nonWorkingHours || this.nonWorkingHoursToDelete) {
        this.nonWorkingHours.forEach((hour) => {
          if (typeof hour.tempId !== "undefined") {
            delete hour.tempId;
          }
        })
        api
            .updateNonWorkingHours(this.nonWorkingHours, this.nonWorkingHoursToDelete)
            .then((res) => {
              this.$root.$emit('successful', 'Дневник обновлен');
              this.loadNonWorkingHours();
            });
      }

    },

    loadNonWorkingHours() {
      api
          .getNonWorkingHours()
          .then((res) => {
            this.nonWorkingHours = res.data.data;
            this.fetchNonWorkHours(this.calendarDate);
          });
    },
    fetchNonWorkHours(date) {
      this.calendarNonWorkingHours = [];
      let formattedDate = moment(date).format('YYYY-MM-DD')
      this.nonWorkingHours.forEach((hour) => {
        let start = moment(hour.date).format('YYYY-MM-DD');
        if (start === formattedDate) {
          let date = moment(hour.date);
          let start = moment(date).toDate();
          let end = moment(date).toDate();
          start.setHours(hour.start.split(':')[0]);
          start.setMinutes(hour.start.split(':')[1]);
          end.setHours(hour.end.split(':')[0]);
          end.setMinutes(hour.end.split(':')[1]);
          //формат бэка неудачный
          this.calendarNonWorkingHours.push(
              {
                id: hour.id,
                start: start,
                end: end
              }
          );
          // this.calendarNonWorkingHours.push(hour);
        }
      });
    }
  }
}
</script>

<template>
  <div class="inner-content">
    <p class="h2">Мой календарь</p>
    <div class="city-chooser">
      <div class="row">
        <div class="col-12 col-md-6"
             v-bind:key="town.city.id"
             v-on:click="selectedCity = town.city"
             v-for="town in towns">
          <div
              class="custom-control custom-radio custom-radio-block custom-radio-block-xl custom-radio-block-light"
              :class="{active:selectedCity.id === town.city.id}"
          >
            <input type="radio" :checked="selectedCity.id === town.city.id" :id="'city-choose-'+town.city.id"
                   class="custom-control-input">
            <label :for="'city-choose-'+town.city.id" class="custom-control-label">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  {{ town.city[$store.state.user.options.locale] }}
                  <span v-if="town.is_main"> (основной)</span>
                </div>
                <div class="count active">{{ town.count_orders }}</div>
              </div>
            </label>
          </div>
        </div>

      </div>
    </div>
    <div class="shadowed-block" v-if="selectedCity">
      <div class="row">
        <div class="col-12 border-bottom col-md-6 border-md-right border-md-bottom-0 travels">
          <datepicker
              :inline="true"
              :language="languages[$store.state.user.options.locale]"
              :fullMonthName="true"
              :highlighted="highlighted"
              :disabledDates="calendarDisableDates({is_main:isMain(), calendar:calendar})"
              v-model="calendarDate"
              :mondayFirst="true"
              class="vdp-datepicker-inline"
              :class="{inverted:isMain()}"></datepicker>

        </div>
        <time-calendar
            ref="timeCalendar"
            :date="calendarDate"
            :orders="calendarOrders"
            :non-working-hours="calendarNonWorkingHours"></time-calendar>
      </div>
      <hr>
      <div class="form-group">
        <button class="btn btn-secondary btn-block btn-text-medium" v-if="calendarDate"
                v-on:click="addNonWorkingHours()">
          Добавить занятое время на {{ calendarDate | moment("DD.MM.YYYY") }}
        </button>
        <b-modal id="modal-NonWorkingHours" centered hide-header hide-footer>
          <div class="modal-header">
            <p class="modal-title modal-brand">Выбрать время</p>
            <div class="close" @click="$bvModal.hide('modal-NonWorkingHours')"></div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <div class="col-6">
                <vue-timepicker format="HH:mm" v-model="nonWorkingHour.start_time" :minute-interval="30"
                                class="time-picker-sm" hide-clear-button></vue-timepicker>
              </div>
              <div class="col-6">
                <vue-timepicker format="HH:mm" v-model="nonWorkingHour.end_time" :minute-interval="30"
                                class="time-picker-sm" hide-clear-button></vue-timepicker>
              </div>
            </div>
          </div>
          <button class="btn btn-secondary btn-block" v-on:click="setNonWorkingHour()">Добавить</button>
        </b-modal>
      </div>
      <button class="btn btn-primary btn-block btn-text-medium" v-on:click="saveJournal()">Сохранить
        изменения
      </button>
    </div>

    <div class="shadowed-block">
      <b-collapse class="p-sm" id="hint-collapse">
        <p class="weight-900">Отключение приёма заказов и сообщений</p>
        <p>Кликните на дате, чтобы выбрать выключить прием заказов, планируйте свой месяц заранее, если у вас есть
          несколько городов приема заказов, тогда дни приема заказов будут выключены в других города в момент
          нахождения в городе проживания.</p>
        <ul>
          <li>Отметьте дни прибывания в других города что бы получить возможность получать заказы в это время</li>
        </ul>
        <p class="weight-900">Важно!</p>
        <ul>
          <li>Доступные для бронирования даты будут белыми в календаре.</li>
          <li>Недоступные даты будут серыми, если они автоматически заблокированы, или будут иметь косую черту,
            если вы заблокировали их вручную.
          </li>
          <li>Даты активные в одном городе будут не активны в других.</li>
          <li>Кликните на Сохранить изменения после редактирования календаря.</li>
        </ul>
        <hr>
      </b-collapse>
      <div class="text-right">
        <b-button v-b-toggle.hint-collapse class="collapse-link collapse-link-bottom">
          <span class="text-collapsed">Показать подсказки</span><span
            class="text-collapse">Скрыть подсказки</span></b-button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>