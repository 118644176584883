<template>
    <div class="inner-content">
        <div class="account-notice">
            {{$t('lk.payment_area.notice')}}
        </div>
<!--        <p class="h2"> {{$t('lk.payment_area.payment_data')}}</p>-->
<!--        <p class="h2-lead"> {{$t('lk.payment_area.notice_2')}}</p>-->
<!--        <card :cards="cards"></card>-->

        <div class="account-separator line"></div>

        <div class="row">
<!--            <div class="col-12 col-md-6">-->
<!--                <p class="h2"> {{$t('lk.payment_area.lvl')}}</p>-->
<!--                <div class="form-group">-->
<!--                    <label>{{$t('lk.payment_area.category')}}</label>-->
<!--                    <b-dropdown no-flip :text=" payments.role ?$t('lk.payment_area.category_list.'+payments.role) : 'Выберите' " class="form-dropdown form-dropdown-sm">-->
<!--                        <b-dd-text v-bind:key="role" v-for="role in roles">-->
<!--                            <div class="dropdown-item" :class="{active: payments.role === role}" v-on:click="payments.role = role">{{$t('lk.payment_area.category_list.'+role)}}</div>-->
<!--                        </b-dd-text>-->
<!--                    </b-dropdown>-->
<!--                    <p class="form-text text-muted">{{$t('lk.payment_area.notice_3')}}</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6">-->
<!--                <p class="h2">{{$t('lk.payment_area.currency')}}</p>-->
<!--                <div class="form-group">-->
<!--                    <label>{{$t('lk.payment_area.currency_type')}}</label>-->
<!--&lt;!&ndash;                   //TODO переделать&ndash;&gt;-->
<!--                    <b-dropdown no-flip :text=" payments.currency !== null ? $t('lk.payment_area.currencies.' + payments.currency) : ''" class="form-dropdown form-dropdown-sm">-->
<!--                        <b-dd-text>-->
<!--                            <div class="dropdown-item"  :class="{active: payments.currency === 'RUB'}" v-on:click="payments.currency = 'RUB'">{{$t('lk.payment_area.currencies.RUB')}}</div>-->
<!--                        </b-dd-text>-->
<!--&lt;!&ndash;                        <b-dd-text>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="dropdown-item" :class="{active: payments.currency === 'USD'}" v-on:click="payments.currency = 'USD'">{{$t('lk.payment_area.currencies.USD')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-dd-text>&ndash;&gt;-->
<!--&lt;!&ndash;                        <b-dd-text>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="dropdown-item" :class="{active: payments.currency === 'EUR'}" v-on:click="payments.currency = 'EUR'">{{$t('lk.payment_area.currencies.EUR')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-dd-text>&ndash;&gt;-->
<!--                    </b-dropdown>-->
<!--                    <p class="form-text text-muted">{{$t('lk.payment_area.notice_4')}}</p>-->
<!--                </div>-->
<!--            </div>-->
          <div class="col-12 col-md-6">
            <p class="h2"> {{$t('lk.payment_area.profession')}}</p>
            <div class="form-group">
<!--              <label>{{$t('lk.payment_area.category')}}</label>-->
              <b-dropdown no-flip :text=" payments.profession ?$t('lk.payment_area.profession_list.'+payments.profession) : 'Выберите' " class="form-dropdown form-dropdown-sm">
                <b-dd-text v-bind:key="profession" v-for="profession in professions">
                  <div class="dropdown-item" :class="{active: payments.profession === profession}" v-on:click="payments.profession = profession">{{$t('lk.payment_area.profession_list.'+profession)}}</div>
                </b-dd-text>
              </b-dropdown>
            </div>
          </div>
        </div>
		
        <div class="account-separator line"></div>
        
		<p class="h2">{{$t('lk.payment_area.lvl_title')}}</p>
		<p class="form-text text-muted">{{$t('lk.payment_area.lvl_description')}}</p>
        <div class="account-separator"></div>
                <matrix level="general"
                        :name="$t('lk.payment_area.lvl_list.general')"
                        :notice="$t('lk.payment_area.notice_5')"
                        :notice_2="$t('lk.payment_area.notice_6')"
                        :values="payments.general"
                        :limits="matrixLimits"
                        :order="order"></matrix>
<!--                <div class="account-separator"></div>-->
<!--        <matrix level="express"-->
<!--                :name="$t('lk.payment_area.lvl_list.express')"-->
<!--                :notice="$t('lk.payment_area.notice_5')"-->
<!--                :notice_2="$t('lk.payment_area.notice_6')"-->
<!--                :values="payments.express"-->
<!--                :order="order"></matrix>-->
<!--        <div class="account-separator"></div>-->
<!--        <matrix level="ultra"-->
<!--                :name="$t('lk.payment_area.lvl_list.ultra')"-->
<!--                :notice="$t('lk.payment_area.notice_5')"-->
<!--                :notice_2="$t('lk.payment_area.notice_6')"-->
<!--                :values="payments.ultra"-->
<!--                :disabled="payments.role === 'beginner' || payments.role === 'middle'"-->
<!--                :order="order"></matrix>-->
<!--        <div class="account-separator"></div>-->
<!--        <matrix level="pro"-->
<!--                :name="$t('lk.payment_area.lvl_list.pro')"-->
<!--                :notice="$t('lk.payment_area.notice_5')"-->
<!--                :notice_2="$t('lk.payment_area.notice_6')"-->
<!--                :values="payments.pro" :order="order"-->
<!--                :disabled="payments.role === 'beginner'"></matrix>-->
<!--        -->
<!--        <div class="account-separator line"></div>-->
<!--        <matrix level="photo_ready"-->
<!--                :name="$t('lk.payment_area.photo_ready')"-->
<!--                :notice="$t('lk.payment_area.select_days_to_work')"-->
<!--                :values="payments.photo"-->
<!--                :limit="60"-->
<!--                :is_money="false"-->
<!--                :order="order"-->
<!--        ></matrix>-->

        <div class="account-separator"></div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <div class="btn btn-secondary btn-block btn-uppercase">{{$t('lk.payment_area.cancel')}}</div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <div class="btn btn-primary btn-block btn-uppercase pointer" v-on:click="save()">{{$t('lk.payment_area.send')}}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Matrix from "./Matrix";
import Api from '../../service';

let api = undefined;

export default {
    name: "PhotographerPayment",
    components: {Matrix},
    data(){
      return{
          newCard:{
              expirationDate: "",
              name: "",
              number: "",
              type: ""
          },
          cards:[],
          roles:[],
          professions:[],
          order:[],
          payments:{
              role:'pro',
              currency:'RUB',
              profession:null,
              payout:{

              }
          },
        matrixLimits: {
          '15m': [250, 0],
          '30m': [250, 0],
          '1h': [500, 0],
          '2h': [1000, 0],
          'day': [1000, 0],
        }
          ,
          default_price_matrix:{
              "15m": 500,
              "30m": 1000,
              "1h": 2000,
              "2h": 4000,
              "day": 10000
          }
      }
    },
    mounted() {
        api = new Api();
        this.load();

    },
    methods:{
        logo(type){
            return 'logo-'+type.toLowerCase();
        },
        load(){
            api
                .getCard()
                .then((res)=>{
                    this.cards = res.data.data.payout;
                })
                .catch((error)=>{
                    this.$root.$emit('error', error.response.data.message);
                });
            api
                .getPayment()
                .then((res)=>{


                    this.payments = res.data.data.payment;
                    this.setDefault(this.payments.express);
                    this.setDefault(this.payments.ultra);
                    this.setDefault(this.payments.pro);

                    this.roles = res.data.data.roles;
                    this.professions = res.data.data.professions;
                    this.order = res.data.data.groups;

                })
                .catch((error)=>{
                    this.$root.$emit('error', error.response.data.message);
                })
        },
        setDefault(matrix){
            if(!matrix || typeof matrix === 'undefined' ){
                matrix = this.default_price_matrix;
            }else{
                for (let [key, value] of Object.entries(matrix)) {
                    if (value === 0){
                        matrix[key] = this.default_price_matrix[key];
                    }
                }
            }

        },
        save(){
            // this.saveCards();
            this.savePayments();
        },
        saveCards(){
            api
                .updateCard(this.cards)
                .then((res)=>{
                    this.$root.$emit('successful', 'Карты сохарнены');
                    // this.payments = res.data.data;
                })
                .catch((error)=>{
                    this.$root.$emit('error', error.response.data.message);
                })
        },
        savePayments(){
            this.setDefault(this.payments.express);
            this.setDefault(this.payments.ultra);
            this.setDefault(this.payments.pro);
            if(!this.payments.role){
              this.payments.role = 'pro';
            }
            if(!this.payments.currency){
              this.payments.currency = 'RUB';
            }
            api
                .updatePayment(this.payments)
                .then((res)=>{
                    this.$root.$emit('successful', 'Отправленно на проверку');
                    // this.payments = res.data.data;
                })
                .catch((error)=>{

                    this.$root.$emit('error', error.response.data.message);
                })
        },
    }
}
</script>

<style scoped>

</style>