import Vue from 'vue'
import moment from 'moment';
import calendar from "@/components/lk/components/page/Calendar.vue";

Vue.mixin({
    methods: {
        calendarDisableDates(data) {
            if (data.is_main === true) {
                let dates = [];
                data.calendar.forEach((calendar) => {
                    if(calendar.is_day_off && data.use_day_off){
                        dates.push(moment(calendar.date).toDate())
                    }
                });
                return {
                    to: moment().add(-1, 'day').endOf("day").toDate(),
                    dates: dates
                }
            } else {
                let leftDate = moment();
                let rightDate = moment();
                let lastDate = moment().add(-1, 'day');
                let ranges = [];
                let today = moment().endOf("day");
                data.calendar.forEach((calendar) => {
                    rightDate = moment(calendar.date);
                    if (rightDate.diff(leftDate, 'days') >= 1) {
                        ranges.push(
                            {
                                from: leftDate.clone(),
                                to: rightDate,
                            }
                        );
                    }
                    leftDate = rightDate.clone().add(1, 'day');
                });
                if (moment(data.calendar[0]?.date).format('YYYY-MM-DD') === moment(today).format('YYYY-MM-DD')) {
                    today.add(-1, 'day')
                }else{
                    // today.add(1, 'day');
                }
                
                lastDate = moment(leftDate);

                return {
                    from: lastDate.toDate(),
                    to: today.toDate(),
                    ranges: ranges,
                }
            }
        },
        getServices(data, block) {
            if (data && data.length > 0) {
                let services = [];
                data.forEach(service => services.push(this.$t('lists.' + block + '.' + service)));
                return services.join(', ');
            } else {
                return '';
            }
        },
        changeDurationHelper(index, increment, obj) {
            let available = this.getOrderDurations();
            if (typeof index === 'undefined') {
                available.filter((a, b) => {
                    if (a.value === this.order.duration.diff(moment().startOf('day'), 'minutes')) {
                        index = b;
                    }
                });
            }
            if (index + increment < 0) {
                return false;
            }
            if (index + increment > available.length) {
                return false;
            }

            index += increment;
            obj.duration = moment().startOf('day').add(available[index].value, 'minutes');
        },


        getOrderDurations() {
            return [
                // {value: 15, tag: '15m'},
                // {value: 30, tag: '30m'},
                {value: 60, tag: '1h'},
                {value: 90, tag: '1,5h'},
                {value: 120, tag: '2h'},
                {value: 150, tag: '2,5h'},
                {value: 180, tag: '3h'},
                {value: 210, tag: '3,5h'},
                {value: 240, tag: '4h'},
                {value: 300, tag: '5h'},
                {value: 540, tag: 'all'},
            ];

        }
    }
})