<template>
  <div class="main-content flex-shrink-0">
    <div class="container" :class="{'container-wide': $route.meta.wideContainer}">
      <div class="row">

        <div class="col-12 col-xl-4 d-none d-xl-block" v-if="!$route.meta.hideSideBar">
          <div class="account-sidebar">
            <div class="account-sidebar-block" v-if="!$auth.user().is_verified">
              <div class="btn btn-bordered btn-block">Аккаунт не подтвержден</div>
            </div>
            <div class="account-sidebar-block">
              <p class="h2">Профиль</p>
              <ul class="account-sidebar-nav">
                <li>
                  <router-link :to="{name:'personal.area'}">Персональные данные</router-link>
                  <div class="label" v-if="!checkFill('personal_fillable')">
                    {{ $store.state.user.fill.personal_fillable.current }} /
                    {{ $store.state.user.fill.personal_fillable.max }}
                  </div>
                  <div class="label" v-if="checkFill('personal_fillable')">
                    <div class="ok"></div>
                  </div>
                </li>
                <li>
                  <router-link :to="{name:'profile.area'}">Обо мне</router-link>
                  <div class="label" v-if="!checkFill('profile_fillable')">
                    {{ $store.state.user.fill.profile_fillable.current }} /
                    {{ $store.state.user.fill.profile_fillable.max }}
                  </div>
                  <div class="label" v-if="checkFill('profile_fillable')">
                    <div class="ok"></div>
                  </div>
                </li>
                <li>
                  <router-link :to="{name:'photos.area'}">Фотографии</router-link>
                  <div class="label">
                    <div class="ok"></div>
                  </div>
                </li>
                <li>
                  <router-link :to="{name:'payment.area'}">Стоимость</router-link>
                  <div class="label" v-if="!checkFill('payment_fillable')">
                    {{ $store.state.user.fill.payment_fillable.current }} /
                    {{ $store.state.user.fill.payment_fillable.max }}
                  </div>
                  <div class="label" v-if="checkFill('payment_fillable')">
                    <div class="ok"></div>
                  </div>
                </li>
                <li>
                  <router-link :to="{name:'calendar.area'}">Календарь</router-link>
                </li>
                <li>
                  <router-link :to="{name:'secure.area'}">Безопасность</router-link>
                  <div class="label">
                    <div class="ok"></div>
                  </div>
                </li>
<!--                <li>-->
<!--                  <router-link :to="{name:'notification.area'}">Оповещения</router-link>-->
<!--                  <div class="label">-->
<!--                    <div class="ok"></div>-->
<!--                  </div>-->
<!--                </li>-->
                <li>
                  <router-link :to="{name:'favorite.area'}">Избранное</router-link>
                </li>
              </ul>
            </div>

            <div class="account-sidebar-block">
              <p class="h2">Заказы</p>
              <ul class="account-sidebar-nav">
                <li class="active">
                  <router-link :to="{name:'order.area'}">Заказы</router-link>
                </li>
              </ul>
            </div>

            <div class="account-sidebar-block border-top" v-if="$auth.user().can_ask_verify">
              <a href="#" class="btn btn-secondary btn-block" v-on:click="send()">Отправить на модерацию</a>
            </div>
            <div class="account-sidebar-block border-top" v-if="$auth.user().can_ask_verify && !$auth.user().is_verified">
              <div class="sidebar-status">Ожидайте верификации</div>
            </div>
          </div>
        </div>
        <div class="col-12" :class="{'col-xl-8':!$route.meta.hideSideBar}">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalAreaApi from "@/components/lk/service";

var api = undefined;
export default {
  name: 'PhotographerArea',
  components: {},
  mounted() {
    api = new PersonalAreaApi();
    setTimeout(()=>{
      this.$hideJivo();
    }, 10000)
    this.$hideJivo();
  },
  methods: {
    checkFill(part) {
      return this.$store.state.user.fill[part].current === this.$store.state.user.fill[part].max;
    },
    send() {
      api
          .askVerify()
          .then(()=>{
            this.$auth.user().can_ask_verify = true;
            this.$root.$emit('successful', 'Профиль успешно отправлен на модерацию. Ожидайте.');
          })
    }
  }
};
</script>

<style scoped>

</style>